<template>
  <router-view></router-view>
</template>
<script>
export default {
  name: '',
  // mixins: [],
  // components: {},
  // props: [],
  data() {
    return {};
  },
  created() {
    this.$store.dispatch('getUserInfo');
  },
  // mounted () {},
  // methods: {},
  watch: {
    $route(to, from) {
      this.$store.dispatch('getUserInfo');
    },
  },
  // computed: {},
  // filters: {},
  // beforeDestroy () {},
};
</script>
<style scoped lang="less"></style>
